//setting all the data for the tabs

const Engineering = [
  {
    title: "Mechatronics Engineering",
    Events: [
      "- Robo Lines(School/College)",
      "- Robo Lines (University)	",
      "- Sumo Bots (Sumo Wrestler Robot)	",
      "- Off-Road RC	",
    ],
    EventDetail: [
      {
        title: "- Robo Lines(School/College)",
        winner: "12,000/-",
        firstRunner: "6000/-",
        total: "18,000/-",
        registrationReg: "1500/-",
        earlyBird: "1000/-",
      },
      {
        title: "- Robo Lines (University)",
        winner: "20,000/-",
        firstRunner: "15,000/-",
        total: "35,000/-",
        registrationReg: "2000/-",
        earlyBird: "1500/-",
      },
      {
        title: "- Sumo Bots (Sumo Wrestler Robot)",
        winner: "20,000/-",
        firstRunner: "10,000/-",
        total: "30,000/-",
        registrationReg: "3000/-",
        earlyBird: "2500/-",
      },
      {
        title: "- Off-Road RC",
        winner: "15,000/-",
        firstRunner: "8000/-",
        total: "23,000/-",
        registrationReg: "1500/-",
        earlyBird: "1200/-",
      }],
    imgSrc:
      "https://img.freepik.com/free-photo/view-graphic-3d-robot_23-2150849173.jpg?t=st=1706539361~exp=1706542961~hmac=4b1c2c63aa4a7f25fef2e34b32e4e79c6d8ee08adb97b1ab453e61c8da885372&w=740",
    advisors: [""],
  },

  {
    title: "Electrical Engineering",
    Events: [
      "- PCB Design	",
      "- DLD Challenge	",
      "- Arduino-X	",
      "- Resistor Roulette	",
    ],
    EventDetail: [
      {
        title: "- PCB Design",
        winner: "70,000/-",
        firstRunner: "30,000/-",
        total: "100,000/-",
        registrationReg: "3200/-",
        earlyBird: "2950/-",
      },
      {
        title: "- DLD Challenge",
        winner: "70,000/-",
        firstRunner: "30,000/-",
        total: "100,000/-",
        registrationReg: "3600/-",
        earlyBird: "3350/-",
      },
      {
        title: "- Arduino-X",
        winner: "70,000/-",
        firstRunner: "30,000/-",
        total: "100,000/-",
        registrationReg: "3300/-",
        earlyBird: "2950/-",
      },
      {
        title: "- Resistor Roulette",
        winner: "70,000/-",
        firstRunner: "30,000/-",
        total: "100,000/-",
        registrationReg: "4300/-",
        earlyBird: "3950/-",
      },
    ],

    imgSrc:
      "https://img.freepik.com/free-photo/close-up-wires-tech-background_23-2148882631.jpg?w=996&t=st=1706536154~exp=1706536754~hmac=de8275367a4ce4f217a90b851d0eb0d8da36608bbc1c511fc8bd5318c13ea4ec",
    advisors: ['" Hussain Asif(Lecturer ECE)"'],
  },

  {
    title: "Mechanical Engineering",
    Events: ["- Air Glider	", "- Air CAD" , '- Air Bridge' , '- Hydraulic Lifting Arm'],
    EventDetail: [
      {
        title: "- Air Glider",
        winner: "20,000/-",
        firstRunner: "5000/-",
        total: "100,000/-",
        registrationReg: "3000/-",
        earlyBird: "2000/-",
      },
      {
        title: "- Air CAD",
        winner: "20,000/-",
        firstRunner: "5000/-",
        total: "100,000/-",
        registrationReg: "3000/-",
        earlyBird: "2000/-",
      },
      {
        title: "- Air Bridge",
        winner: "20,000/-",
        firstRunner: "5000/-",
        total: "25000/-",
        registrationReg: "3000/-",
        earlyBird: "2000/-",
      },
      {
        title: "- Hydraulic Lifting Arm",
        winner: "20,000/-",
        firstRunner: "5000/-",
        total: "25000/-",
        registrationReg: "3000/-",
        earlyBird: "2000/-",
      },
    ],
    imgSrc:
      "https://images.unsplash.com/photo-1581093803931-46e730e7622e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    advisors: [""],
  },

  {
    title: "Biomedical Engineering",
    Events: ["Artificial Health Care Medical Devices"],
    EventDetail: [
      {
        title: "- Artificial Health Care Medical Devices",
        winner: "15,000/-",
        firstRunner: "5000/-",
        total: "20,000/-",
        registrationReg: "1500/-",
        earlyBird: "1000/-",
      }],
    imgSrc:
      "https://images.unsplash.com/photo-1580983230786-ce385a434707?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    advisors: ["Javaria Babar (Lab Engineer BME)"],
  },
];

const Gaming = [
  {
    title: "AU E-Gaming (Society)   ",
    Events: [
      "- FIFA 24	",
      "- TEKKEN 7	",
      "- COD4	",
      "- Counter-Strike 2 (CS2)	",
      "- Street Fighter	",
      "- VALORANT	",
      "- Dota	",
    ],
    EventDetail: [
      {
        title: "- FIFA 24",
        winner: "30,000/-",
        firstRunner: "12,000/-",
        total: "42,000/-",
        registrationReg: "2000/-",
        earlyBird: "1500/-",
      },
      {
        title: "- TEKKEN 7",
        winner: "30,000/-",
        firstRunner: "12,000/-",
        total: "42,000/-",
        registrationReg: "1500/-",
        earlyBird: "1000/-",
      },
      {
        title: "- COD4",
        winner: "30,000/-",
        firstRunner: "15,000/-",
        total: "45,000/-",
        registrationReg: "2500/-",
        earlyBird: "1500/-",
      },
      {
        title: "- Counter Strike 2 (CS2)",
        winner: "20,000/-",
        firstRunner: "10,000/-",
        total: "30,000/-",
        registrationReg: "2000/-",
        earlyBird: "1500/-",
      },
      {
        title: "- Street Fighter",
        winner: "20,000/-",
        firstRunner: "10,000/-",
        total: "30,000/-",
        registrationReg: "1500/-",
        earlyBird: "1000/-",
      },
      {
        title: "- VALORANT",
        winner: "25,000/-",
        firstRunner: "10,000/-",
        total: "35,000/-",
        registrationReg: "2000/-",
        earlyBird: "1500/-",
      },
      {
        title: "- Dota",
        winner: "25,000/-",
        firstRunner: "10,000/-",
        total: "35,000/-",
        registrationReg: "2000/-",
        earlyBird: "1500/-",
      }
    ],
    imgSrc:
      "https://images.unsplash.com/photo-1534423861386-85a16f5d13fd?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    advisors: ["AU E-Gaming Society"],
  },
];

const compCompetitions = [
  {
    title: "Cyber Security",
    Events: ["- CTF Hackathon	"],
    EventDetail: [
      {
        title: "- CTF Hackathon",
        winner: "50,000/-",
        firstRunner: "25,000/-",
        total: "75,000/-",
        registrationReg: "2000/-",
        earlyBird: "1500/-",
      }
    ],
    imgSrc:
      "https://images.unsplash.com/photo-1548092372-0d1bd40894a3?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    advisors: ["Khawaja Mansoor"],
  },

  {
    title: "Creative Technologies",
    Events: ["- UI/UX Design Contest	", "- Data Quest	"],
    EventDetail: [
      {
        title: "- UI/UX Design Contest",
        winner: "5000/-",
        firstRunner: "-",
        total: "5000/-",
        registrationReg: "500/-",
        earlyBird: "500/-",
      },
      {
        title: "- Data Quest",
        winner: "15,000/-",
        firstRunner: "10,000/-",
        total: "25,000/-",
        registrationReg: "2000/-",
        earlyBird: "1500/-",
      }],
    imgSrc:
      "https://img.freepik.com/free-photo/medium-shot-man-wearing-vr-glasses_23-2149126949.jpg?w=996&t=st=1706535959~exp=1706536559~hmac=1029ee57bd347c15f7277195fd4dd22d2f34ed6d79803b3dd100e1c7fd831420",
    advisors: ["Hafiz Anas" , "Dr Usman"],
  },

  {
    title: "Computer Sciences ",
    Events: [
      "- CodeChamp (Code Air 3.0)	",
      "- Webathon (Web Development)	",
      "- Appathon (App Development)",
      "- Dev Dynasty (Ideathon)"
    ],
    EventDetail: [
      {
        title: "- CodeChamp (Code Air 3.0)",
        winner: "25,000/-",
        firstRunner: "15,000",
        total: "40,000",
        registrationReg: "1500/-",
        earlyBird: "1200/-",
      },
      {
        title: "- Webathon (Web Development)",
        winner: "15,000/-",
        firstRunner: "10,000/-",
        total: "25,000/-",
        registrationReg: "2000/-",
        earlyBird: "1500/-",
      },
      {
        title: "- Appathon (App Development)",
        winner: "20,000/-",
        firstRunner: "10,000/-",
        total: "30,000/-",
        registrationReg: "2500/-",
        earlyBird: "3000/-",
      },
      {
        title: "- Dev Dynasty (Ideathon)",
        winner: "15,000/-",
        firstRunner: "10,000/-",
        total: "25,000/-",
        registrationReg: "500/-",
        earlyBird: "500/-",
      }
     ],
    imgSrc:
      "https://img.freepik.com/free-photo/html-css-collage-concept-with-person_23-2150062008.jpg?w=826&t=st=1706536035~exp=1706536635~hmac=492877d1b25df08d63140786d133546911c6fb7b149b04f91cb8079de8b164f5",
    advisors: [""],
  },

  {
    title: "Faculty Of Computing & AI",
    Events: [
      "- Computing Quiz Competition	",
      '- Project Exhibition'
    ],
    EventDetail: [
      {
        title: "- Computing Quiz Competition",
        winner: "20,000/-",
        firstRunner: "10,000",
        total: "30,000",
        registrationReg: "1000/-",
        earlyBird: "500/-",
      },
      {
        title: "- Project Exhibition",
        winner: "40,000/-",
        firstRunner: "20,000",
        total: "60,000",
        registrationReg: "2000/-",
        earlyBird: "2000/-",
      }
    
     ],
    imgSrc:
      "https://img.freepik.com/free-photo/person-using-ai-tool-job_23-2150714247.jpg?w=826&t=st=1706536072~exp=1706536672~hmac=1d3c9a04496734e1687e295816c98863f2dafd203cd2bc9f3f86ba27032cfbe2",
    advisors: ["Dr.Arifullah"],
  },
];

const appliedSciences = [
  {
    title: "Mathematics",
    Events: ["- Model presentation	", "- Math Circle	", "- Poster presentation	"],
    EventDetail: [
      {
        title: "- Model presentation",
        winner: "5000/-",
        firstRunner: "3000",
        total: "8000",
        registrationReg: "700/-",
        earlyBird: "500/-",
      },
      {
        title: "- Math Circle",
        winner: "5000/-",
        firstRunner: "3000",
        total: "8000",
        registrationReg: "700/-",
        earlyBird: "500/-",
      },
      {
        title: "- Poster presentation",
        winner: "5000/-",
        firstRunner: "3000",
        total: "8000",
        registrationReg: "700/-",
        earlyBird: "500/-",
      },
     ],
    imgSrc:
      "https://images.unsplash.com/photo-1632571401005-458e9d244591?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    advisors: ['" Dr. Tanzeela Shaheen (Assistant Prof. Mathematics)"'],
  },

  {
    title: "Physics",
    Events: [
      "- Science Exhibition",
      "- Physics Treasure Hunt	",
      "- Physics Quiz	",
      "- Egg Drop",
    ],
    EventDetail: [
      {
        title: "- Science Exhibition",
        winner: "10000/-",
        firstRunner: "5000",
        total: "15,000",
        registrationReg: "1000/-",
        earlyBird: "700/-",
      },
      {
        title: "- Physics Treasure Hunt",
        winner: "5000/-",
        firstRunner: "2000",
        total: "7000",
        registrationReg: "800/-",
        earlyBird: "500/-",
      },
      {
        title: "- Physics Quiz",
        winner: "7000/-",
        firstRunner: "4000",
        total: "11,000",
        registrationReg: "800/-",
        earlyBird: "600/-",
      },
      {
        title: "- Egg Drop",
        winner: "5000/-",
        firstRunner: "3000",
        total: "8,000",
        registrationReg: "800/-",
        earlyBird: "500/-",
      },
     ],
    imgSrc:
      "https://img.freepik.com/free-photo/stack-books-with-pencil-holder-glasses-against-chalkboard_181624-38995.jpg?w=900&t=st=1706536871~exp=1706537471~hmac=df403f1c95a1ab44834878f060bc13d123d5cf0c564d879169d1b09339408c19",
    advisors: ['"Anan Ashraf (Lecturer Physics)"'],
  },
];

const managementSciences = [
  {
    title: "AU Aviation Society",
    Events: [
      "- Aviation Knowledge Quiz		",
      "- Case Study Analysis	",
      "- Simulated Airline Management Game		",
    ],
    EventDetail: [
      {
        title: "- Science Exhibition",
        winner: "10000/-",
        firstRunner: "5000",
        total: "15,000",
        registrationReg: "1000/-",
        earlyBird: "700/-",
      },
      {
        title: "- Physics Treasure Hunt",
        winner: "5000/-",
        firstRunner: "2000",
        total: "7000",
        registrationReg: "800/-",
        earlyBird: "500/-",
      },
      {
        title: "- Physics Quiz",
        winner: "7000/-",
        firstRunner: "4000",
        total: "11,000",
        registrationReg: "800/-",
        earlyBird: "600/-",
      },
      {
        title: "- Egg Drop",
        winner: "5000/-",
        firstRunner: "3000",
        total: "8,000",
        registrationReg: "800/-",
        earlyBird: "500/-",
      },
     ],
    imgSrc:
      "https://img.freepik.com/free-photo/bottom-view-plane-sky_1134-408.jpg?w=826&t=st=1706537871~exp=1706538471~hmac=e005d3f8019584b9d1e9847495e97b925abc111b6c07126a978d6b148689f6c1",
    advisors: ['"Air Cdr. Khalil(Aviation Consultent, AUSOM)"'],
  },

  {
    title: "AU Tax Awarness Society",
    Events: ["- Investing Simulations	"],
    EventDetail: [
      {
        title: "- Investing Simulations",
        winner: "30,000/-",
        firstRunner: "15,000",
        total: "45,000",
        registrationReg: "3000/-",
        earlyBird: "3000/-",
      },],
    imgSrc:
      "https://img.freepik.com/free-photo/tax-incentive-audit-benefit-cash-payment-income-concept_53876-123661.jpg?w=826&t=st=1706538053~exp=1706538653~hmac=50950bf7234bb0f14e10f9b24e470b63fed8e09db3b989552aa58943a12bfb43",
    advisors: ["Nil"],
  },
  {
    title: "AU Startup Club",
    Events: ["- InnovateX Pitch Challenge	"],
    EventDetail: [
      {
        title: "- InnovateX Pitch Challenge",
        winner : 'Matchmaking platform',
        total: "-",
        registrationReg: "2000/-",
        earlyBird: "1500/-",
      },],
    imgSrc:
      "https://img.freepik.com/premium-photo/colorful-start-up-sketch-whiteboard_780608-31178.jpg?w=740",
    advisors: ["Nil"],
  },

  {
    title: "AUSOM",
    Events: ["- Scavenger Hunt	"],
    EventDetail: [
      {
        title: "- Scavenger Hunt",
        winner: "10,000/-",
        firstRunner: "-",
        total: "10,000",
        registrationReg: "500/-",
        earlyBird: "500/-",
      },],
    imgSrc:
      "https://img.freepik.com/free-photo/close-up-laptop-with-economic-report_1098-2544.jpg?w=826&t=st=1706538190~exp=1706538790~hmac=11e0e1be2ca796d9db433298d872c17d5a2eff7904c43334f528471d834756a8",
    advisors: ["Nil"],
  },
];

const SocialScience = [
  {
    title: "Social Sciences",
    Events: ["- AU Digital Linguistics Olympiad-2024	"],
    EventDetail: [
      {
        title: "- AU Digital Linguistics Olympiad-2024",
        winner: "20,000/-",
        firstRunner: "15,000/-",
        total: "35,000",
        registrationReg: "2300/-",
        earlyBird: "2000/-",
      },],
    imgSrc:
      "https://img.freepik.com/free-photo/globe-glasses_23-2147689768.jpg?w=900&t=st=1706538762~exp=1706539362~hmac=14bfc57d937020276ca18ff42a8a7899b22331fbc9ac84092056f7cddf34bafa",
    advisors: ["Dr Sadia Irshad ", "& Dr Tehseen Zahra"],
  },
];
const creativeExpModule = [
  {
    title: "Student Affairs",
    Events: [
      "- Logo Design Competition	",
      "- Poster Design Competition	",
      "- CineScape Portrait Video competition	",
      "- FrameScape Photography Competition	",
    ],
    EventDetail: [
      {
        title: "- Logo Design Competition",
        winner: "6000/-",
        firstRunner: "4000/-",
        total: "10,000",
        registrationReg: "1000/-",
        earlyBird: "1000/-",
      },
      {
        title: "- Poster Design Competition",
        winner: "6000/-",
        firstRunner: "4000/-",
        total: "10,000",
        registrationReg: "1000/-",
        earlyBird: "1000/-",
      },
      {
        title: "- CineScape Portrait Video competition	",
        winner: "20,000/-",
        firstRunner: "10,000/-",
        total: "30,000",
        registrationReg: "1000/-",
        earlyBird: "750/-",
      },
      {
        title: "- AFrameScape Photography Competition	",
        winner: "30,000/-",
        firstRunner: "15,000/-",
        total: "45,000",
        registrationReg: "1000/-",
        earlyBird: "750/-",
      }],
    imgSrc:
      "https://img.freepik.com/free-photo/group-diverse-grads-throwing-caps-up-sky_53876-56031.jpg?w=826&t=st=1706539217~exp=1706539817~hmac=d9bd89be83fdac5a0863fc583434c11c5eeb43de7a41d2956bf4069a5b3f304c",
    advisors: ['"Asim Fayaz(Lecturer CS Dept.)"', "Air Media Club"],
  },
];

const Sports = [
  {
    title: "Student Affairs ",
    Events: [
      "- Football (Table Football)	",
      "- Chess	",
      "- Ludo	",
      "- Volleyball	",
      "- Tug of War	"
    ],
    EventDetail: [
      {
        title: "- Football",
        winner: "5000/-",
        firstRunner: "3000/-",
        total: "8000",
        registrationReg: "1000/-",
        earlyBird: "-",
      },
      {
        title: "- Chess",
        winner: "10,000/-",
        firstRunner: "5000/-",
        total: "15,000",
        registrationReg: "1000/-",
        earlyBird: "-",
      },  {
        title: "- Ludo",
        winner: "10,000/-",
        firstRunner: "5000/-",
        total: "15,000",
        registrationReg: "1000/-",
        earlyBird: "-",
      },  {
        title: "- Voleyball",
        winner: "15,000/-",
        firstRunner: "8000/-",
        total: "23,000",
        registrationReg: "2500/-",
        earlyBird: "-",
      },  {
        title: "- Tug of War",
        winner: "20,000/-",
        firstRunner: "10,000/-",
        total: "30,000",
        registrationReg: "2500/-",
        earlyBird: "-",
      },],
    imgSrc:
      "https://img.freepik.com/free-photo/sports-tools_53876-138077.jpg?w=900&t=st=1706539158~exp=1706539758~hmac=ee4c2d39f8d8c1b9b752b5277c7a7175a22e093046f9098ff173eb0e6616e3ba",
    advisors: [" AU Sports & Adventure Club"],
  },
];

export {
  Engineering,
  Gaming,
  compCompetitions,
  appliedSciences,
  managementSciences,
  SocialScience,
  creativeExpModule,
  Sports,
};
