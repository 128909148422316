const ambassadors = [
  {
    name: 'Abdul Wahab',
    title: 'NUML Isb',
    // linkedin: 'https://www.linkedin.com/',
    imgSrc: './ambassador/AbdulWahab.jpeg',
    whatsapp: 'https://wa.me/923325165100',
  },
  {
    name: 'Talha Sheikh',
    title: 'NDU',
    //   linkedin: 'https://www.linkedin.com/',
    imgSrc: './ambassador/TalhaSheikh.jpeg',
    whatsapp: 'https://wa.me/923556616998',
  },
  {
    name: 'Soban',
    title: 'SZABIST',
    // linkedin: 'https://www.linkedin.com/',
    imgSrc: './ambassador/Soban.jpg',
    whatsapp: 'https://wa.me/923330544446',
  },
  {
    name: 'M Abdullah Tanveer',
    title: 'FUUAST',
    // linkedin: '',
    imgSrc: './ambassador/MuhammadAbdullahTanveer.jpeg',
    whatsapp: 'https://wa.me/923324386596',
  },
  {
    name: 'Laraib',
    title: 'IIUI',
    // linkedin: 'https://www.linkedin.com/',
    imgSrc: './ambassador/Laraib.png',
    whatsapp: 'https://wa.me/923020956119',
  },
  {
    name: 'Nayab',
    title: 'NUST H-12',
    // linkedin: 'https://www.linkedin.com/',
    imgSrc: './ambassador/nayan.jpg',
    whatsapp: 'https://wa.me/923326285445',
  },
  {
    name: 'Syed Ameer Hussain',
    title: 'NUML H-9',
    // linkedin: 'https://www.linkedin.com/',
    imgSrc: './ambassador/SyedAmeerHussain.jpg',
    whatsapp: 'https://wa.me/9223315976760',
  },
  {
    name: 'Ali Husnain Saqib',
    title: 'COMSATS Wah Campus',
    // linkedin: 'https://www.linkedin.com/',
    imgSrc: './ambassador/AliHusnainSaqib.jpg',
    whatsapp: 'https://wa.me/923377167775',
  },
  {
    name: 'Munizza Mehreen',
    title: 'Air University Kamra Campus',
    // linkedin: 'https://www.linkedin.com/',
    imgSrc: './ambassador/MunizzaMehreen.jpeg',
    whatsapp: 'https://wa.me/923115289244',
  },
  {
    name: 'Syed Rafay Hassan',
    title: 'FAST isb',
    // linkedin: '',
    imgSrc: './ambassador/syedRafayHassan.jpeg',
    whatsapp: 'https://wa.me/923138009538',
  },
  {
    name: 'Muhammad Sheheryar',
    title: 'UET Taxila',
    // linkedin: '',
    imgSrc: './ambassador/MuhammadSheheryar.jpeg',
    whatsapp: 'https://wa.me/923185419054 ',
  },
  {
    name: 'Huzaifa Bin Imran',
    title: 'Bahria University ISB',
    // linkedin: 'https://www.linkedin.com/in/mswaseem/',
    imgSrc: './ambassador/huzaifa.jpeg',
    whatsapp: 'https://wa.me/923369642469',
  },
  {
    name: 'Asif Hamid',
    title: 'Air University Kamra Campus',
    // linkedin: 'https://www.linkedin.com/in/mswaseem/',
    imgSrc: './ambassador/AsifHamid.jpg',
    whatsapp: 'https://wa.me/923180510009',
  },
  {
    name: 'M. Uzair',
    title: 'PIEAS',
    // linkedin: 'https://www.linkedin.com/in/wasifkamal/',
    imgSrc: './ambassador/M.Uzair.jpg',
    whatsapp: 'https://wa.me/923261157567',
  },
  {
    name: 'Muhammad Huzaifa Zafar',
    title: 'Quaid-i-Azam University, Islamabad',
    // linkedin: 'https://www.linkedin.com/',
    imgSrc: './ambassador/MuhammadHuzaifaZafar.jpg',
    whatsapp: 'https://wa.me/923066066615',
  },
  {
    name: 'Amina Hashmi',
    title: 'University of Wah',
    // linkedin: 'https://www.linkedin.com/',
    imgSrc: './ambassador/AminaHashmi.jpg',
    whatsapp: 'https://wa.me/923215644836',
  },
  {
    name: 'Hassan Raza',
    title: 'PIEAS',
    // linkedin: 'https://www.linkedin.com/',
    imgSrc: './ambassador/HassanRaza.jpg',
    whatsapp: 'https://wa.me/923019670009',
  },
  {
    name: 'Daniyal Aashar',
    title: 'NUST',
    // linkedin: 'https://www.linkedin.com/',
    imgSrc: './ambassador/daniyal.jpg',
    whatsapp: 'https://wa.me/923187149610',
  },
  {
    name: 'Shazeen Fatima',
    title: 'Quaid-e-Azam University, Islamabad',
    // linkedin: 'https://www.linkedin.com/',
    imgSrc: './ambassador/shazeenFatima.jpeg',
    whatsapp: 'https://wa.me/923335708693',
  },

  {
    name: 'Rohan',
    title: 'Bagh Grammar School team',
    // linkedin: 'https://www.linkedin.com/',
    imgSrc: './ambassador/Rohan.jpg',
    whatsapp: 'https://wa.me/923149796567',
  },

  {
    name: 'Nawal Aslam',
    title: 'Quaid-e-Azam University, Islamabad',
    // linkedin: '',
    imgSrc: './ambassador/NawalAslam.jpg',
    whatsapp: 'https://wa.me/923046956897',
  },
  {
    name: 'Ali Mumtaz',
    title: 'COMSATS University Islamabad',
    // linkedin: '',
    imgSrc: './ambassador/AliMumtaz.jpg',
    whatsapp: 'https://wa.me/923191727879',
  },
  {
    name: 'Unaiza Zafar',
    title: 'FJWU',
    // linkedin: '',
    imgSrc: './ambassador/unaizazafar.jpeg',
    whatsapp: 'https://wa.me/923200557347',
  },
  {
    name: 'Zaeem Muhammad Yaseen',
    title: 'FAST NUCES CFD',
    // linkedin: 'https://www.linkedin.com/',
    imgSrc: './ambassador/ZaeemMuhammadYaseen.jpg',
    whatsapp: 'https://wa.me/923146535791',
  },
]

export default ambassadors
