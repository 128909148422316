import React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Engineering, Gaming , compCompetitions, appliedSciences, managementSciences , SocialScience , creativeExpModule , Sports} from './TabContents';
import Cards from './Cards';
import { useState } from 'react';
import { Helmet } from 'react-helmet'

export default function EventsPage() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <section className='event-page section-spacing pt-5'>
      <Helmet>
        <title>Events || AirTech '24</title>
        <meta name="description" content="Events hosted by Airtech '24" />
      </Helmet>
      <div className='container mt-32'>
        <div className="section-heading text-center mb-5">
          <h6>Our events</h6>
          <h1>Choose an event</h1>
        </div>
        
        <Box sx={{ width: '100%', bgcolor: 'background.paper', margin :'auto' }} >
        <div className='flex justify-center items-center'>
          <Tabs value={value} onChange={handleChange}  variant="scrollable"  scrollButtons="auto"
             aria-label="scrollable auto tabs example" centered>
            <Tab label="Engineering"  />
            <Tab label="E-Gaming" />
            <Tab label="Computing Comeptition" />
            <Tab label="Applied Sciences" />

            <Tab label="Management Sciences" />
            <Tab label="Social Sciences" />
            <Tab label="Creative Expression Module" />
            <Tab label="Sports" />

          </Tabs>
          </div>
        </Box>
        <br />
        {/* Display content based on the selected tab */}
        <div className="tab-content flex flex-wrap justify-center mt-4 gap-4">
          {/* {tabContents[value].message} */}
          { value === 0 ?
          
            Engineering.map((card)=>{
            return(
              <>
              <div className="">
              <Cards
              title = {card.title}
              Event={ card.Events}
              image = {card.imgSrc}
              EventDetails = {card.EventDetail}
              advisors = {card.advisors}
              
              />
              </div>
              </>
              )
          })
          : (value === 1) ?
          Gaming.map((card)=>{
            return(
              <>
              <div className="">
              <Cards
              title = {card.title}
              Event={ card.Events}
              image = {card.imgSrc}
              EventDetails = {card.EventDetail}
              advisors = {card.advisors}/>
              
              </div>
              </>
              
              )
          })  : 
          (value === 2) ?
          compCompetitions.map((card)=>{
            return(
              <>
              <div className="">
              <Cards
              title = {card.title}
              Event={ card.Events}
              image = {card.imgSrc}
              EventDetails = {card.EventDetail}
              advisors = {card.advisors}/>
              </div>
              </>
              
              )
          }):
          (value === 3) ?
          appliedSciences.map((card)=>{
            return(
              <>
              <div className="">
              <Cards
              title = {card.title}
              Event={ card.Events}
              image = {card.imgSrc}
              EventDetails = {card.EventDetail}
              advisors = {card.advisors}/>
              </div>
              </>
              
              )
          }):
          (value === 4) ?
           managementSciences.map((card)=>{
            return(
              <>
              <div className="">
              <Cards
              title = {card.title}
              Event={ card.Events}
              image = {card.imgSrc}
              EventDetails = {card.EventDetail}
              advisors = {card.advisors}/>
              </div>
              </>
              
              )
          }) : 
          (value === 5) ?
           SocialScience.map((card)=>{
            return(
              <>
              <div className="">
              <Cards
              title = {card.title}
              Event={ card.Events}
              image = {card.imgSrc}
              EventDetails = {card.EventDetail}
              advisors = {card.advisors}/>
              </div>
              </>
              
              )
          }) :
          (value === 6) ?
           creativeExpModule.map((card)=>{
            return(
              <>
              <div className="">
              <Cards
              title = {card.title}
              Event={ card.Events}
              image = {card.imgSrc}
              EventDetails = {card.EventDetail}
              advisors = {card.advisors}/>
              </div>
              </>
              
              )
          }) :
          (value === 7) ?
           Sports.map((card)=>{
            return(
              <>
              <div className="">
              <Cards
              title = {card.title}
              Event={ card.Events}
              image = {card.imgSrc}
              EventDetails = {card.EventDetail}
              advisors = {card.advisors}/>
              </div>
              </>
              
              )
          }) : (<></>)
        }
          
        </div>
      </div>
    </section>
  );
}